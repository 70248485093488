import React from "react"
import styled from "styled-components"


import { LargeMaxWidth } from "components/Layouts/MaxWidth"

import { FlexParent } from "components/Layouts/Utils"


const JewelSection = ({
	img,
	children
}) => {
	return (
		<LargeMaxWidth>
			<Section>
				<img className="ornament" src='/images/how-to-play/ornament.png' alt="" />
				<FlexParent justifyContent="inherit">
					<ImgContainer>
						<img src={img} alt="" />
					</ImgContainer>
					<TextContainer>
						{children}
					</TextContainer>
				</FlexParent>

			</Section>
		</LargeMaxWidth>
	)
}

const ImgContainer = styled.div`
	width: 40%;
	padding: 2rem;
	text-align: center;

	img {
		width: 100%;
		margin: auto;
		max-width: 30rem;
	}

	@media (max-width: 780px) {
		width: 100%;
	}
`

const TextContainer = styled.div`
	width: 60%;
	max-width: 28rem;

	@media (max-width: 780px) {
		width: 100%;
		max-width: unset;
	}
`

const Section = styled.section`
position: relative;
	width: 100%;
	background-color: var(--bgLight);
	border: 1px solid var(--darkBorder);
	margin-bottom: 6rem;
	position: relative;
	padding: 4rem 2rem;


	.ornament {
		position: absolute;
		top:-56px;
		height: 90px;
		left: 50%;
		pointer-events: none;
		 transform: translateX(-50%);
	}

	@media (max-width: 750px) { 
		margin-bottom: 4rem;
		width: calc(100% - 2rem);
		margin-left:auto;
		margin-right:auto;

	}

`


export default JewelSection