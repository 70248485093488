
import React from "react";

import "css/main.css";
import "css/fonts.css";

import Seo from "components/SeoHead";
import CreatorsProgram from "components/Community/CreatorsProgram"

import data from "data/community/creators-program-data"

function SocialFeedPage() {
    return (
        <>
            <Seo title="Creators Program - Skyweaver" />
            <CreatorsProgram
                {...data}
            />
        </>
    )
}

export default SocialFeedPage