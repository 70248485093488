import React from "react";
import styled from "styled-components"
import { withPrefix } from "gatsby";


import HowItWorks from "./HowItWorks"
import Join from "./Join"
import JewelSection from "./JewelSection";

import { ButtonLink } from "components/Button";

import { P, H1, H2, Sub } from "components/Typography"

import { MainContentMaxWidth, MedMaxWidth, SmallMaxWidth } from "components/Layouts/MaxWidth";

import FeaturedHeader from "components/Blog/FeaturedHeader";
import { FlexParent } from "components/Layouts/Utils";

const Landing = styled.div`
	img.title {
		height: 200px;
		width: auto;
	}

	@media (max-width: 800px) {
		img.title {
			height: auto;
			width: 100%;
		}
	}

	margin-bottom: 4rem;
	
`

const Parent = styled.div`
	width: 100%;
	position: relative;
	padding: 8rem 2rem;
	margin-top: -10rem;
	padding-bottom: 4rem;

	@media (max-width: 800px) {
		padding-left:0;
		padding-right: 0;
		padding-bottom:0;
		margin-bottom:2rem;
	}

	background-image: url('/images/community/creators-program/AmbassadorsBackground.jpg');
	background-size: cover;
	background-position: center;

	&::before {
		height: 8rem;
		width: 100%;
		background:linear-gradient(var(--background), transparent);
		top:0;left:0;
		position: absolute;
		content: " ";
	}
	&::after {
		height: 8rem;
		width: 100%;
		background:linear-gradient(transparent, var(--background));
		bottom:0;left:0;
		position: absolute;
		content: " ";
	}
`

const IntroText = styled.div`

	padding: 2rem 0;
	margin-bottom: 4rem;
`

const Ambassadors = ({ }) => {
	return (
		<>
			<Landing>
				<FeaturedHeader>
					<img className="title" src={'/images/community/creators-program/ambassadors-program-logo.png'} alt="Ambassadors Program" />
				</FeaturedHeader>
			</Landing>


			<MedMaxWidth>
				<IntroText>
					<div style={{
						marginBottom: "4.6rem"
					}}>
						<H2
							size="2.56rem"
							style={{
								color: "white",
							}}>What is the Ambassadors Program?</H2>
						<P>Ever wanted to create and publish content about a game and get paid for it? Well, this program is just that! A special tier of the Skyweaver Creators Program is the Ambassadors Program. You must submit an application to Horizon Blockchain Games in order for us to review your application before getting approved into this Ambassadors Program.</P>

					</div>

					<div>
						<H2
							size="2.56rem"
							style={{
								color: "white",
							}}>What’s the difference between the Creators Program and the Ambassadors Program?</H2>
						<P>The main difference is that as a Skyweaver Ambassador, upon approval by Horizon Blockchain Games, you will be paid to publish content about Skyweaver. All official Skyweaver Ambassadors approved into this program are automatically considered part of the Creators Program and will reap the same benefits outlined in the Creators Program.</P>

						<P>Official Skyweaver Ambassadors will also receive an ultra rare and exclusive Amber Crystal after a minimum of three months of creating content for Skyweaver.</P>

					</div>
				</IntroText>
			</MedMaxWidth>


			<MainContentMaxWidth>
				<FeaturedHeader>
					<H2>How to Become aN Ambassador</H2>
				</FeaturedHeader>

				<FlexParent style={{
					padding: "4rem 0rem",
					marginBottom: "2rem"
				}}>
					<Card i={1} subtitle={"one"} text="Apply to the program by filling out this form." title="Apply" img="/images/community/creators-program/creator-apply.jpg" applyLink={"https://hd609222.typeform.com/SW-ambassador?utm_source=Typeform&utm_medium=Survey&utm_campaign=ApplicationTraffic_Ongoing&utm_term=Typeform&utm_content=Skyweaver_AmbassadorsProgram_Application"} />
					<Card i={2} subtitle={"two"} text="We will contact you individually by email to get more information before you can be accepted to the program." title="create" img="/images/community/creators-program/creator-create.jpg" />
					<Card i={3} subtitle={"three"} text="Reap the rewards and benefits of being an official Skyweaver Ambassador!" title="get paid" img="/images/community/creators-program/creator-paid.jpg" />
				</FlexParent>

			</MainContentMaxWidth>

			<JewelSection img={'/images/community/creators-program/orange.png'}>
				<H2
					size="2.56rem"
					style={{
						color: "white"
					}}>Exclusive Amber Crystal</H2>
				<div style={{

				}}>

					<P>This ultra-rare Amber Crystal will be exclusive to approved Ambassadors who have been creating and publishing content about Skyweaver for at least three months.</P>
				</div>


				<Sub color="var(--lilac)">
					Like the other Skyweaver Crystals, this Crystal will change your in-game Skytag color name, showing everyone that you are a big deal in our community.
				</Sub>

				<img style={{
					width: "100%",
					marginTop: "2rem"
				}} src="/images/community/creators-program/creator-400.svg" alt="Limited edition given to the first 400 active members" />

			</JewelSection>


			<Parent>

				<MainContentMaxWidth>
					<FeaturedHeader>
						<H2>Apply to become an ambassador</H2>
					</FeaturedHeader>

					<SmallMaxWidth style={{
						padding: "2rem 0rem",
						textAlign: "center"
					}}>
						<P>Complete this form to submit your application! Once approved into the program, be amongst the upper echelon of Creators for Skyweaver, get prestige and aim to get your very own Amber Crystal!</P>

						<ApplyLink><P>Submissions Closed</P></ApplyLink>
					</SmallMaxWidth>

				</MainContentMaxWidth>

				<MedMaxWidth>
					<LearnMore>
						<a href="/news/creators-program-update">
							<img style={{ width: "100%" }} src="/images/community/creators-program/learn-more.jpg" alt="" />
						</a>
						<div className="text">

							<H2 color="white" size="2.4rem"><a
								style={{
									color: "white",
									textDecoration: "none"
								}}
								href="/news/creators-program-update">Learn more about the Ambassadors <br /> Program in this blog post.</a></H2>
							<P>Got any questions about the Ambassadors Program? Shoot us an email at <a href="mailto:SWambassadors@horizon.io">SWambassadors@horizon.io</a></P>
						</div>
					</LearnMore>

				</MedMaxWidth>




			</Parent>



		</>
	)
}



const LearnMore = styled.div`
	width: 100%;
	background-color: var(--bgLight);
	border: 1px solid var(--darkBorder);
	margin-bottom: 6rem;
	position: relative;
	text-align: center;

	div {
		padding: 4rem;
		padding-top:0;
	}

	@media (max-width: 800px) {
		div {
			padding: 1rem;
		}
	}

	div a {text-decoration: underline;}

	p {
		color: var(--lilac);
	}

	
`

const Card = ({
	img, subtitle, i,
	title, text,
	applyLink
}) => {
	return (
		<StyledCard img={img}>
			<div className="content">
				<div className="text">
					<div className="offset-num">
						<H2 size="6rem">{i}</H2>
					</div>

					<Sub style={{ textTransform: "uppercase" }}>Step {subtitle}</Sub>
					<H2 style={{ color: "white" }} size="2.2rem">{title}</H2>
					<P>{text}</P>
				</div>
				<figure />
				{applyLink && <div className="btn-container">
					<P style={{
						textAlign: "center",
					}}>Submissions Closed</P>
				</div>}
			</div>

		</StyledCard>
	)
}

const StyledCard = styled.div`
	width: 33%;
	padding: 0.5rem;



	.btn-container {

		position: absolute;
		bottom: 0;left:0;
		width: 100%;
		padding: 2rem;

		a {width: 100%;}
	}

	.offset-num {
		position: absolute;
		top: 0.5rem;left:1rem;
		opacity: 0.1;
	}

	
	.text {
		position: relative;
		z-index: 3;
		padding: 3rem;
	}

	.content {
		background-color: var(--bgLight);
		background-image: url(${({ img }) => img});
		background-size: 100%;
		background-position: left bottom;
		position:relative;
		height: 700px;
	}
	.content::after {
		top: 0;left:0;
		position: absolute;
		height: 40%;
		width: 100%;
		background: linear-gradient(var(--bgLight), transparent);
		content: " ";
	}

	@media (max-width: 800px) {
		width: 100%;
		.content{ 
			height: 500px;
		}

	}
`


const ApplyLink = styled.div`
	width: 100%;
	margin: 2rem 0;
	a {width: 100%}
`


export default Ambassadors


