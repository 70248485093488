const data = {
    titleHTML: `We want to <br/> help you grow`,
    subtitle: 'By helping you grow your audience, Skyweaver grows as a game and as a community',
    img: {
        url: "/images/community/creators-program/creators-cover-deskop.jpg"
    },
    howItWorks: {
        title: "How It Works",
        content: [
            {
                img: {
                    url: "/images/community/creators-program/creators-1.jpg"
                },
                title: "WHAT IS THE PROGRAM?",
                text: "We want to see you grow as a content creator and expand the limits of what you can produce. To do so, we want to offer you some help and the recognition you deserve. All kinds of creators are welcome!",
                points: [
                    "Increase your skills",
                    "Be a pioneer",
                    "Expand your reach",
                ]

            },
            {
                img: {
                    url: "/images/community/creators-program/creators-2.jpg"
                },
                title: "WHAT DO WE OFFER?",
                text: "Approved Creators will have a number of perks available to them, and we want to hear what the creators want from this program, too. We are building several rewards for our partners, but right now, we have:",
                points: [
                    "Access to exclusive assets",
                    "Digital items",
                    "A special Discord Role",

                ]

            },
            {
                img: {
                    url: "/images/community/creators-program/creators-3.jpg"
                },
                title: "CAN I JOIN?",
                text: "Yes! All we want are motivated people that are keen on creating and growing their platforms, whatever they may be. We welcome videos, livestreaming, podcast, fansites, blogs, fanfiction, cosplayers, and pretty much anything else. Be creative!",
                points: [
                    "Community building",
                    "Livestreaming",
                    "Fanart and fanfic, and more!"
                ]
            }
        ]
    },
    moreInfo: {
        subtitle: "More Information",
        title: "Want to know more and get all the details?",
        text: "Click the “Tell me more!” button and read a detailed explanation of what the Creators Program is about, its requirements and what you can get by joining it.",
        buttonTitle: "Tell me more",
        buttonLink: "https://skyweaver.net"
    },
    join: {
        title: "Join The Creators Crew",
        text: "Join the Creators Program by signing up with your email in the box below. We will never share your information with anyone outside Horizon Games.",
        buttonTitle: "FOLLOW @SKYWEAVERGAME ON INSTAGRAM FOR MORE",
        buttonLink: "https://instagram.com/skyweavergame"
    }
}

export default data