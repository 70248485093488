import React, { useEffect, useRef } from "react"
import animateScrollTo from "animated-scroll-to";
import styled from "styled-components"

import Layout from "components/Blog/Layout"

import Landing from "./Landing"
import Creators from "./CreatorsProgram"
import Ambassadors from "./AmbassadorsProgram"

import Block from "components/CallToAction/Block"
import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { animatedScrollTo } from "utililty";


const League = styled.div`
    .main-media-content, .gradient {
        opacity: 0;
        pointer-events: none;
    }
`

const CreatorsProgram = ({
    titleHTML, img, howItWorks, join, subtitle
}) => {

    const creatorsRef = useRef(null)
    const ambassadorsRef = useRef(null)

    const scrollToCreator = () => {
        animateScrollTo(creatorsRef.current)
    }
    const scrollToAmbassador = () => {
        animateScrollTo(ambassadorsRef.current)
    }

    useEffect(() => {
        // scroll to id section
        if (window.location.hash) animatedScrollTo(window.location.hash)
    }, [])


    return (
        <Layout>
            <Landing
                scrollToAmbassador={scrollToAmbassador}
                scrollToCreator={scrollToCreator}
                subtitle={subtitle} title={titleHTML} img={img} />

            <div
                id="#creators"
                style={{
                    overflow: "hidden"
                }} ref={creatorsRef}>
                <Creators howItWorks={howItWorks} join={join} />
            </div>

            <div
                id="#ambassadors"
                style={{
                    overflow: "hidden"
                }}
                ref={ambassadorsRef}>
                <Ambassadors />
            </div>

            <MainContentMaxWidth>
                <League>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.skyweaver.net/news/tournaments-deep-dive">
                        <Block title={"Skyweaver Competitive League"} text="Competitive League Coming Soon, Skyweaver Competitive League" buttonLink={""} buttonTitle=""
                            img={"/images/community/creators-program/league.jpg"}
                        />
                    </a>
                </League>

            </MainContentMaxWidth>

            <MainContentMaxWidth>
                <Block title={"Skyweaver Streamer Kit"} text="Download the Streamer Kit to start streaming or making videos right now!" buttonLink={"https://drive.google.com/drive/folders/1xhX_fpfoN24iOk4LrJKA1z5FzP9l1CaK"} buttonTitle="DOWNLOAD STREAMER KIT
                " />
            </MainContentMaxWidth>

        </Layout>
    )
}

export default CreatorsProgram