import animatedScroll2 from 'animated-scroll-to'

export const animatedScrollTo = (id, speed = 500, offset = 0) => {
  const settings = {
    speed,
    offset
  }

  let el = document.getElementById(id)

  animatedScroll2(el, settings)
}

export const getBackgroundScalingValues = (x, y, parentValues) => {
  let windowHeight = window.innerHeight
  let windowWidth = window.innerWidth

  let scale
  let xScale = windowWidth / parentValues.width
  let yScale = windowHeight / parentValues.height
  let xOffset = 0
  let yOffset = 0

  if (xScale > yScale) {
    // The image fits perfectly in x axis, stretched in y
    scale = xScale
    yOffset = (windowHeight - (parentValues.height * scale)) / 2
  } else {
    scale = yScale
    xOffset = (windowWidth - (parentValues.width * scale)) / 2
  }

  
  return { 
    top: y * scale + yOffset,
    left: x * scale + xOffset
  }
}

export const shouldComponentUpdateBasedOnMouseMovement = (props, nextProps) => {
  // if component is not in view and if there's no animation then dont render
  if (!nextProps.isVisible) return false
  else {
    if (nextProps.mousePos.x === props.mousePos.x) {
      return false
    } else return true
  }
}
