import React from "react"
import styled from "styled-components"

import { MainContentMaxWidth } from "components/Layouts/MaxWidth"
import { FlexParent } from "components/Layouts/Utils"
import { H2, P, CheckMark } from "components/Typography"

const StyledBlock = styled.div`
    width: 33.33%;
    padding: 1.5rem 2rem;

    h2 {
        text-align: center;
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`

const ImgContainer = styled.div`
    width: 70%;
    padding-bottom: 70%;
    margin: auto;
    border-radius: 50%;
    margin-bottom: 1.5rem;
    position: relative;
    overflow: hidden;

    img {
        position: absolute;
        top:0;left:0;
        width: 100%;
        height: 100%;
        object-position: cover;
    }

    @media (max-width: 768px) {
        width: 50%;
        padding-bottom: 50%;
    }
`

const Block = ({
    img, title, text, points
}) => (
    <StyledBlock>
        <ImgContainer>
            <img alt="" loading="lazy" src={img.url} />
        </ImgContainer>
        <H2 color="white" size="2rem">{title}</H2>
        <P>{text}</P>
        {points.map((check, i) => <CheckMark key={i}>
            {check}
        </CheckMark>)}
    </StyledBlock>
)

const HowItWorks = ({
    title, content
}) => {
    return (
        <MainContentMaxWidth style={{
            marginBottom: "6rem"
        }}>
            <FlexParent
                alignItems="flex-start"
            >
                {content.map(block => <Block {...block} key={block.title} />)}
            </FlexParent>
        </MainContentMaxWidth>
    )
}

export default HowItWorks