import React from "react"
import styled from "styled-components"

import { LargeMaxWidth } from "components/Layouts/MaxWidth"
import { H2, P } from "components/Typography"
import { BackgroundImage, Gradient, FlexParent } from "components/Layouts/Utils"
import { withPrefix } from "gatsby-link"



const StyledLanding = styled.section`
    position: relative;
    width: 100%;
    padding: 4rem;
    padding-top: 8rem;
    padding-bottom: 8rem;
    overflow: hidden;

    @media (max-width: 750px) {
        padding: 4rem 2rem;
    }
`

const Logo = styled.img`
    max-width: 24rem;
    height: auto;
    width: 100%;
    margin-bottom: 2rem;
`


const ContentMaxWidth = styled.div`
    max-width: 30rem;
    margin: auto;
    width:50%;

    @media (max-width: 760px) {
        order:2;
        width: 100%;
        text-align: center;
    }
`

const ButtonContainer = styled.div`
    margin: 2rem 0;

    button {
        height: 6rem;
        margin-right: 1rem;
        margin-bottom: 1rem;
        transition: 0.3s ease-out opacity;
    }
    button:hover {opacity: 0.6;}
    img {
        height: 100%;
    }
`

const LogoParent = styled.div`
    max-width: 50rem;
    width: 50%;
    text-align: center;

    @media (max-width: 760px) {
        order:1;
    }
`

const Landing = ({
    title, img, subtitle,
    scrollToCreator,
    scrollToAmbassador
}) => {
    return (
        <StyledLanding>
            <BackgroundImage
                src={img.url}
            />

            <Gradient opacity="1" />

            <LargeMaxWidth style={{
                padding: "4rem 0rem",
                paddingBottom: "0rem",
            }}>
                <FlexParent>
                    <ContentMaxWidth className="content">
                        <H2 size="4rem" color="white"><span dangerouslySetInnerHTML={{ __html: title }} /></H2>
                        <P>{subtitle}</P>

                        <ButtonContainer>
                            <button onClick={scrollToCreator}>
                                <img src={withPrefix(`/images/community/creators-program/creators-program-btn.png`)} />
                            </button>

                            <button onClick={scrollToAmbassador}>
                                <img src={withPrefix(`/images/community/creators-program/ambassadors-program-btn.png`)} />
                            </button>

                        </ButtonContainer>
                    </ContentMaxWidth>

                    <LogoParent>
                        <Logo
                            height="367"
                            width="292"
                            loading="lazy"
                            src={withPrefix(`/images/community/creators-program/creators-logo-solo.png`)} alt="Creators Program" />
                    </LogoParent>
                </FlexParent>


            </LargeMaxWidth>
        </StyledLanding>
    )
}


export default Landing