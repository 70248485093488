import React from "react"
import styled from "styled-components";

import FeaturedHeader from "components/Blog/FeaturedHeader"
import { LargeMaxWidth, SmallMaxWidth } from "components/Layouts/MaxWidth"
import { P } from "components/Typography"
import RedeemContent from "components/Signup";


const Join = ({ title, text }) => {
  return (
    <LargeMaxWidth style={{
    }}>
      <FeaturedHeader>{title}</FeaturedHeader>

      <SmallMaxWidth style={{ textAlign: "center" }}>
        <P>{text}</P>
      </SmallMaxWidth>

      {/* <JoinForm /> */}
      <StyledRedeemContent creatorSignup />

    </LargeMaxWidth>
  )
}


const StyledRedeemContent = styled(RedeemContent)`
  height: 100%;
  min-height: 0;
  margin: 0 auto;
  margin-top: -4rem;
  background-color: transparent;

  .background,
  .subtitle,
  .gradient,
  h2 {
    display: none;
  }

  form {
    padding: 0;
  }

  @media (max-width: 800px) {
    width: 90%;
  }
`;


export default Join