import React from "react";
import styled from "styled-components"
import { withPrefix } from "gatsby";


import HowItWorks from "./HowItWorks"
import Join from "./Join"
import JewelSection from "./JewelSection";

import { P, H2, Sub } from "components/Typography"

import FeaturedHeader from "components/Blog/FeaturedHeader";

const Landing = styled.div`
	img.title {
		height: 200px;
		width: auto;
	}

	@media (max-width: 800px) {
		img.title {
			height: auto;
			width: 100%;
		}
	}
	
	margin-bottom: 4rem;
	
`

const JoinParent = styled.div`
	width: 100%;
	position: relative;
	padding: 12rem 2rem;
	padding-bottom: 7rem;
	margin-top: -10rem;
	overflow: hidden;
	margin-bottom: 5rem;

	@media (max-width: 800px) {
		padding-bottom:0rem;
		margin-bottom:2rem;
	}

	background-image: url('/images/community/creators-program/CreatorsBackground.jpg');
	background-size: cover;
	background-position: center;

	// &::before {
	// 	height: 8rem;
	// 	width: 100%;
	// 	background:linear-gradient(var(--bgLight), transparent);
	// 	top:0;left:0;
	// 	position: absolute;
	// 	content: " ";
	// }
	// &::after {
	// 	height: 8rem;
	// 	width: 100%;
	// 	background:linear-gradient(transparent, var(--bgLight));
	// 	bottom:0;left:0;
	// 	position: absolute;
	// 	content: " ";
	// }
`

const Creators = ({ howItWorks, join }) => {
	return (
		<>
			<div style={{
				paddingTop: "4rem",
				backgroundColor: "var(--bgLight)"
			}}>

				<Landing>
					<FeaturedHeader>
						<img className="title" src={'/images/community/creators-program/creators-program-logo.png'} alt="Creators Program" />
					</FeaturedHeader>
				</Landing>



				<HowItWorks {...howItWorks} />
				<JewelSection img={'/images/community/creators-program/blue.png'}>

					<H2
						size="2.56rem"
						style={{
							color: "white"
						}}>Exclusive Members Reward</H2>
					<div style={{
					}}>

						<P>Join the Creators Program, be an active part of the community, create Skyweaver content, and be eligible for a rare Sapphire Crystal! Only 800 available. Given to the first 800 active members*</P>
					</div>


					<Sub color="var(--lilac)">
						Like the other Skyweaver Crystals, this Crystal will change your in-game Skytag color name, showing everyone that you are a big deal in our community.
					</Sub>

					<br />

					<Sub color="var(--lilac)">
						*Hand picked by our Community Team
					</Sub>
				</JewelSection>

				{/* <MoreInfo {...moreInfo} /> */}
				<JoinParent>
					<Join {...join} />
				</JoinParent>
			</div>


		</>
	)
}

export default Creators